import { DateTime } from 'luxon'
import { Client } from './api/therapists/models/Client'
import { EventRule } from './api/therapists/models/EventRule'
import { getDayTitleByStart } from './functions'

const COMPONENT_TEXTS = {
    forms: {
        reset: 'Verwerfen',
        save: 'Speichern',
        saved: 'Gespeichert',
        multiselect: {
            creatableNoOptionsMessage: 'Tippen Sie um einen Eintrag hinzuzufügen',
            noOptionsMessage: 'Kein Eintrag gefunden',
            formatCreateLabel: (inputValue: string) => `"${inputValue}" erstellen`,
            addPlaceholder: 'Hinzufügen...',
            noOptionsSelectMessage: 'Keine Optionen verfügbar',
            isNew: 'Neu'
        }
    },
    functions: {
        plans: {
            free: 'Kostenlos',
            paid: 'Terminbuchung'
        },
        days: {
            monday: 'Montag',
            tuesday: 'Dienstag',
            wednesday: 'Mittwoch',
            thursday: 'Donnerstag',
            friday: 'Freitag',
            saturday: 'Samstag',
            sunday: 'Sonntag'
        },
        meetingProviders: {
            zoom: 'Zoom',
            microsoftTeams: 'Microsoft Teams'
        },
        errors: {
            defaultMessage: 'Ups, da ist leider etwas schief gelaufen. Bitte versuchen Sie es später erneut.'
        }
    },
    guards: {
        paidPlanRequired: 'Der Tebuto Terminbuchung-Plan wird für diese Funktionalität benötigt',
        configuration: {
            title: 'Konfigurieren Sie Ihr Profil',
            description: 'Fügen Sie Informationen hinzu, die für Ihre Patienten relevant sind.',
            divider: 'oder',
            manual: {
                title: 'Informationen',
                generalTitle: 'Allgemeine Informationen',
                addressTitle: 'Adresse',
                name: 'Name Ihrer Praxis',
                nameDescription: 'Dies sollte der Name sein, unter dem Ihre Klienten Sie kennen',
                streetAndNumber: 'Straße und Hausnummer',
                additionalInformation: 'Anfahrtsbeschreibung (optional)',
                zip: 'Postleitzahl',
                city: 'Ort',
                importFromGoogle: 'Zurück'
            },
            import: {
                title: 'Aus Google Maps importieren',
                description:
                    'Wenn Ihr Unternehmen bereits bei Google Maps gelistet ist, können Sie es bequem importieren. Alternativ können Sie die Daten unten auch direkt eingeben.',
                searchPlaceholder: 'Unternehmensname eingeben',
                noResults: 'Keine Ergebnisse gefunden',
                search: 'Suchen'
            },
            errors: {
                mandatory: 'Pflichtangabe',
                invalidZip: 'Ungültige Postleitzahl'
            }
        },
        plan: {
            title: 'Wählen Sie Ihr Abonnement',
            description: 'Sie können Ihr Abonnement später jederzeit ändern.',
            choose: 'Auswählen',
            active: 'Derzeit Aktiv',
            test: 'Kostenlos testen',
            free: {
                name: 'Free',
                priceMonthly: 'Kostenlos',
                description: 'Weniger Stress. Mehr Zeit. Private Online-Terminbuchung für Therapie, Beratung und Coaching.',
                features: ['Unbegrenzte Termine', 'Website Terminplugin', '25 Klient:innen', '3 Terminkategorien', '1 Kalender verbinden']
            },
            paid: {
                name: 'Basic',
                priceMonthly: '69€',
                priceIndicator: 'pro Monat',
                description: 'Online-Terminbuchung ohne Limitierungen. Kontrolle behalten und noch mehr Zeit sparen.',
                features: ['Keine Limitierungen', 'Ausfallhonorare', 'Online-Meetings', 'Benachrichtigungen anpassen', 'Öffentliches Profil'],
                testForFree: true
            }
        },
        billing: {
            title: 'Abonnement hinzufügen',
            description: 'Fügen Sie Ihre Zahlungsinformationen hinzu, um Ihr Tebuto-Abonnement zu aktivieren. Sie können Ihr Abonnement jederzeit monatlich kündigen.'
        },
        waitlist: {
            title: 'Warteliste',
            heading: 'Vielen Dank für Ihr Interesse an Tebuto!',
            description:
                'Unser Produkt befindet sich derzeit in der Beta-Phase und wir beschränken den Zugang um sicherzustellen, dass wir Ihnen die bestmögliche Erfahrung bieten können.',
            why: {
                title: 'Warum eine Warteliste?',
                description:
                    'Da sich Tebuto noch in der Beta-Phase befindet, möchten wir zunächst sicherstellen, dass alles reibungslos funktioniert und unsere hohen Qualitätsstandards erfüllt werden. Wir laden schrittweise neue Benutzer ein, um sicherzustellen, dass wir auf jede Rückmeldung eingehen und Verbesserungen vornehmen können.'
            },
            benefits: {
                title: 'Was erwartet Sie?',
                news: {
                    title: 'Neuigkeiten',
                    description:
                        'Als Teil unserer Warteliste erhalten Sie regelmäßige Updates über den Fortschritt von Tebuto. Sie sind immer auf dem neuesten Stand und erfahren als Erste, wenn es wichtige Entwicklungen gibt.'
                },
                exclusiveAccess: {
                    title: 'Exklusiver Zugang',
                    description:
                        'Sobald Tebuto verfügbar ist oder wir neue Benutzer in unser Beta-Programm aufnehmen, werden Sie von uns informiert und erhalten Zugang. Sie werden unter den ersten Nutzern sein, die Tebuto in vollem Umfang nutzen können.'
                }
            },
            nextSteps: {
                title: 'Wie geht es weiter?',
                description:
                    'Sie brauchen nichts weiter zu tun. Wir halten Sie per E-Mail auf dem Laufenden. Sollten wir Sie für das Beta-Programm auswählen oder Tebuto allgemein verfügbar sein, benachrichtigen wir Sie und Sie können direkt loslegen.'
            },
            stayInContact: {
                title: 'Bleiben wir in Kontakt',
                description:
                    'Wir schätzen Ihre Geduld und Ihr Interesse sehr. Sobald Sie Zugang zu Tebuto haben, freuen wir uns auf Ihr Feedback, um das Produkt weiter zu verbessern. Bis dahin können Sie uns bei Fragen und Anliegen jederzeit erreichen.',
                contact: 'Kontaktformular',
                phoneNumber: '0721 5095 8279'
            },
            end: 'Wir freuen uns darauf, Sie bald bei Tebuto begrüßen zu dürfen!'
        },
        clientTherapistSelection: {
            noTherapist: {
                title: 'Kein:e Therapeut:in vorhanden',
                description1:
                    'Aktuell hat Sie niemand zur Nutzung von Tebuto eingeladen. Das kann entweder bedeuten, dass Ihr:e Therapeut:in Tebuto noch nicht nutzt, oder diese:r Sie noch nicht als Klient:in hinzugefügt hat.',
                description3:
                    'Bitte wenden Sie sich an Ihre:n Therapeut:in und erkundigen Sie sich, ob diese:r Tebuto nutzt und Sie bereits mit Ihrer E-Mail als Klient:in hinzugefügt hat. Alternativ können Sie eine:n Therapeut:in suchen, der/die bereits Tebuto nutzt.',
                findTherapists: 'Therapeut:in in Ihrer Nähe suchen'
            },
            therapistsAvailable: {
                title: 'Therapeut:in wählen',
                description: 'Sie sind Klient:in oder Patient:in bei mehreren Therapeuten. Bitte wählen Sie die Praxis in der Sie Ihren Termin buchen möchten.',
                owner: 'Inhaber:in',
                ownerMale: 'Inhaber',
                ownerFemale: 'Inhaberin',
                select: 'Auswählen'
            }
        }
    },
    icons: {
        google: 'Google',
        googleCalendar: 'Google Kalender',
        googleMaps: 'Google Maps',
        microsoftTeams: 'Microsoft Teams',
        microsoftOutlook: 'Microsoft Outlook',
        zoom: 'Zoom',
        yahoo: 'Yahoo',
        wordpress: 'Wordpress'
    },
    layout: {
        startPage: 'Startseite',
        loading: 'Wird geladen ...',
        loadingAnimation: 'Lade-Animation',
        profilePicture: 'Profilbild',
        search: 'Suche',
        close: 'Schließen',
        copyright: `${DateTime.now().year} Tebuto.de. Alle Rechte vorbehalten.`,
        app: {
            profile: 'Profil',
            dashboard: 'Dashboard',
            events: 'Terminorganisation',
            eventCategories: 'Terminkategorien',
            meetings: 'Online-Meetings',
            calendars: 'Kalender',
            settings: 'Einstellungen',
            clients: 'Klienten',
            appointments: 'Termine',
            communication: 'Kommunikation',
            account: 'Account',
            roadmap: 'Roadmap',
            support: 'Hilfe',
            notifications: 'Benachrichtigungen',
            collapse: 'Einklappen',
            expand: 'Ausklappen',
            logout: 'Logout',
            openSidebar: 'Sidebar öffnen',
            closeSidebar: 'Sidebar schließen'
        },
        client: {
            dashboard: 'Dashboard',
            booking: 'Termin buchen',
            settings: 'Einstellungen',
            logout: 'Abmelden',
            confirmLogout: 'Möchten Sie sich wirklich abmelden?',
            landing: 'Online-Terminbuchung',
            imprint: 'Impressum',
            privacy: 'Datenschutzerklärung'
        },
        landing: {
            footerTitle: 'Footer',
            slogan: 'Private Online-Terminbuchung für Therapie, Beratung und Coaching',
            toApp: 'Zur App',
            menu: {
                register: 'Kostenlos starten',
                createEntry: 'Eintrag erstellen',
                home: 'Startseite',
                aboutUs: 'Über uns',
                contact: 'Kontakt',
                documentation: 'Dokumentation',
                directory: 'Therapeuten-Verzeichnis',
                status: 'Status',
                roadmap: 'Roadmap',
                impress: 'Impressum',
                privacyDeclaration: 'Datenschutzerklärung',
                usage: 'Funktionen',
                price: 'Preis',
                blog: 'Blog',
                testimonials: 'Kundenstimmen',
                waitlist: 'Warteliste',
                faq: 'FAQ',
                features: {
                    title: 'Funktionen',
                    cancellationFee: {
                        title: 'Ausfallhonorare',
                        subtitle: 'Weniger Terminausfälle, mehr Fairness',
                        description: 'Ein Ausfallhonorar schützt Ihre Zeit, unterstreicht den Wert jeder Sitzung und bleibt flexibel: Sie entscheiden, wann es angewendet wird.',
                        imgSrc: '/assets/screenshots/ausfallhonorar.webp',
                        mobileImgSrc: '/assets/screenshots/ausfallhonorar-mobile.webp',
                        href: '/features/ausfallhonorar'
                    },
                    calendarIntegration: {
                        title: 'Kalender-Integration',
                        subtitle: 'Doppelbuchungen und Engpässe vermeiden',
                        description: 'Optimieren Sie Ihre Terminplanung durch nahtlose Synchronisation mit bestehenden Kalendern und vermeiden Sie Doppelbuchungen.',
                        imgSrc: '/assets/screenshots/kalender.webp',
                        mobileImgSrc: '/assets/screenshots/kalender-mobile.webp',
                        href: '/features/kalender-integration'
                    },
                    clientBooking: {
                        title: 'Privates Klientenportal',
                        subtitle: 'Weniger Stress, mehr Klientenzufriedenheit',
                        description: 'Bieten Sie Ihren Klient:innen/Patient:innen Flexibilität und Komfort - ohne zusätzlichen Aufwand für Sie.',
                        imgSrc: '/assets/screenshots/termine-buchen.webp',
                        mobileImgSrc: '/assets/screenshots/termine-buchen-mobile.webp',
                        href: '/features/klientenportal'
                    },
                    dataProtection: {
                        title: 'Datenschutz und DSGVO',
                        subtitle: 'Patientendaten schützen',
                        description: 'Patientendaten sollten mit höchster Sorgfalt behandelt werden.',
                        imgSrc: '/assets/carousel/datenschutz.webp',
                        mobileImgSrc: '/assets/carousel/datenschutz.webp',
                        href: '/features/datenschutz-und-dsgvo'
                    },
                    onlineMeeting: {
                        title: 'Online-Meetings',
                        subtitle: 'Unterstützung bei virtuellen Terminen',
                        description: 'Führen Sie Ihre Sitzungen bei Bedarf online durch - sicher, einfach und flexibel.',
                        imgSrc: '/assets/screenshots/online-meetings.webp',
                        mobileImgSrc: '/assets/screenshots/online-meetings-mobile.webp',
                        href: '/features/online-meetings'
                    },
                    appointmentManagement: {
                        title: 'Termin-Management',
                        subtitle: 'Alle Termine, übersichtlich an einem Ort',
                        description: 'Behalten Sie Ihre Termine stets im Blick und organisieren Sie sie effizient.',
                        imgSrc: '/assets/screenshots/alle-termine-im-blick.webp',
                        mobileImgSrc: '/assets/screenshots/alle-termine-im-blick-mobile.webp',
                        href: '/features/termin-management'
                    },
                    individualNotifications: {
                        title: 'Automatisierte Kommunikation',
                        subtitle: 'Zeit sparen ohne Qualitätsverlust',
                        description:
                            'Optimieren Sie Ihre Praxisabläufe mit automatisierten Benachrichtigungen und Erinnerungen, ohne dabei an Kontrolle oder Qualität zu verlieren.',
                        imgSrc: '/assets/screenshots/individuelle-nachrichten-und-erinnerungen.webp',
                        mobileImgSrc: '/assets/screenshots/individuelle-nachrichten-und-erinnerungen-mobile.webp',
                        href: '/features/automatisierte-kommunikation'
                    },
                    publicBooking: {
                        title: 'Terminbuchung-Plugin',
                        subtitle: 'Termine auf der eigenen Webseite anbieten',
                        description: 'Bieten Sie Ihre Termine auf Ihrer eigenen Webseite an. Einfach, schnell und ohne Programmierkenntnisse.',
                        imgSrc: '/assets/screenshots/terminbuchung-widget.webp',
                        mobileImgSrc: '/assets/screenshots/terminbuchung-widget-mobile.webp',
                        href: '/features/terminbuchung-plugin'
                    }
                },
                support: 'Support',
                links: 'Wichtige Links',
                login: 'Anmelden',
                law: 'Rechtliches',
                avv: 'Auftragsverarbeitungsvertrag',
                cookieSettings: 'Cookie-Einstellungen',
                terms: 'AGB'
            },
            social: {
                linkedIn: 'LinkedIn',
                linkedInTitle: 'Tebuto auf LinkedIn',
                instagram: 'Instagram',
                instagramTitle: 'Tebuto auf Instagram',
                gitHub: 'GitHub',
                gitHubTitle: 'Tebuto auf GitHub',
                youtube: 'YouTube',
                youtubeTitle: 'Tebuto auf YouTube'
            }
        }
    },
    modals: {
        bookedEventSlideOver: {
            recurring: ({ interval, start }: EventRule) =>
                interval > 0 ? `Jeden ${interval === 1 ? '' : `${interval}.`} ${getDayTitleByStart(DateTime.fromISO(start) as DateTime<true>)}` : 'Keine',
            booking: 'Termin',
            outageFee: 'Ausfallhonorar',
            outageFeeUsed: 'In Anspruch genommen',
            outageFeeNotUsed: 'Nicht in Anspruch genommen',
            status: 'Status',
            showClient: 'Anzeigen',
            unknown: 'Unbekannt',
            actions: 'Aktionen',
            deletedClient: 'Gelöschte:r Klient:in',
            selectedLocation: 'Gewählter Ort',
            inPastInfo: 'Der Termin liegt in der Vergangenheit, er kann nicht mehr bearbeitet werden.',
            skippedInfo: 'Dieser Termin ist für Ihre Klient:innen nicht buchbar, da er sich mit anderen Terminen überschneidet.',
            skipped: 'Ausgesetzt',
            eventSkipped: 'Termin wurde ausgesetzt',
            buttons: {
                bookManually: 'Vergeben',
                skip: 'Manuell bearbeiten',
                release: 'Freigeben',
                move: 'Verschieben',
                joinOnlineMeeting: 'Online-Meeting beitreten',
                editEventRule: 'Bearbeiten',
                acceptRequest: 'Annehmen',
                declineRequest: 'Ablehnen',
                cancelBooking: 'Absagen',
                eventIsOutage: 'Terminausfall erfassen',
                useOutageFee: 'Ausfallhonorar fordern',
                skipOutageFee: 'Ausfallhonorar nicht fordern',
                deleteEventRule: 'Löschen'
            },
            manualBookingDialogue: {
                title: 'Termin Vergeben',
                submitButton: 'Vergeben',
                description: 'Sie können Termine auch ganz bequem selbst für Klient:innen buchen. Diese:r wird darüber informiert und kann den Termin auch selbst verwalten.',
                client: 'Klient:in',
                where: 'Wo soll der Termin stattfinden?',
                onsite: 'Vor Ort',
                virtual: 'Online',
                couldNotLoadClients: 'Klient:innen konnten nicht geladen werden',
                eventBooked: 'Termin wurde erfolgreich gebucht',
                personalMessage: 'Persönliche Nachricht (optional)'
            },
            skipEventDialogue: {
                title: 'Termin manuell bearbeiten',
                submitButton: 'Speichern',
                eventTitle: 'Titel (optional)',
                description:
                    'Wenn Sie diesen Termin außerhalb von Tebuto vergeben möchten oder er aus anderen Gründen nicht für Klient:innen buchbar sein soll, können Sie ihn hiermit reservieren bzw. aussetzen.',
                success: 'Termin wurde erfolgreich aktualisiert'
            },
            releaseEventDialogue: {
                title: 'Termin zur Buchung freigeben',
                submitButton: 'Freigeben',
                description: 'Möchten Sie den Termin wirklich freigeben? Der Termin wird damit wieder für alle Klient:innen buchbar.',
                eventReleased: 'Termin wurde erfolgreich freigegeben'
            },
            moveBookingDialogue: {
                title: 'Termin verschieben',
                submitButton: 'Verschieben',
                description: 'Sie können den Termin verschieben, indem Sie ein neues Datum und eine neue Uhrzeit auswählen.',
                date: 'Datum',
                time: 'Uhrzeit'
            },
            rejectBookingDialogue: {
                title: 'Buchung ablehnen',
                description: 'Möchten Sie die Buchung wirklich ablehnen? Der Klient wird darüber informiert und kann sich einen neuen Termin buchen.',
                submitButton: 'Ablehnen',
                messageLabel: 'Nachricht an Klient:in (optional)'
            },
            cancelBookingDialogue: {
                title: 'Buchung absagen',
                description: 'Möchten Sie die Buchung wirklich absagen? Der Klient wird darüber informiert und kann sich einen neuen Termin buchen.',
                submitButton: 'Absagen',
                messageLabel: 'Nachricht an Klient:in (optional)',
                eventBookableAgainLabel: 'Termin wird wieder frei und kann gebucht werden'
            },
            outageDialogue: {
                title: 'Termin als Ausfall markieren',
                submitButton: 'Markieren',
                description: (client: Client, outageFeeEnabled: boolean) =>
                    client &&
                    `${client.firstName} ${client.lastName} ist nicht zum vereinbarten Termin erschienen? Sie können den Termin
                            als Ausfall markieren${outageFeeEnabled && ' und das Ausfallhonorar einfordern wenn Sie das möchten'}.`,
                requireOutageFeeLabel: 'Ausfallhonorar einfordern'
            },
            deleteEventRuleDialogue: {
                title: 'Serientermin löschen',
                description: 'Möchten Sie diesen Serientermin wirklich löschen? Alle zukünftigen Termine, die aus dieser Serie stammen, werden ebenfalls gelöscht.',
                submitButton: 'Löschen'
            }
        }
    },
    types: {
        genders: {
            female: 'Frau',
            male: 'Herr',
            diverse: 'Divers',
            notSet: 'nicht angegeben'
        },
        eventStatus: {
            notBooked: 'Nicht gebucht',
            booked: 'Angefragt',
            approved: 'Bestätigt',
            rejected: 'Abgelehnt',
            cancelled: 'Abgesagt',
            outage: 'Ausgefallen',
            skipped: 'Ausgesetzt',
            currentlyInBooking: 'Reserviert'
        },
        location: {
            notFixed: 'Vor Ort oder Online',
            onsite: 'Vor Ort',
            virtual: 'Online'
        }
    },
    requestGate: {
        defaultEmptyMessage: 'Keine Einträge vorhanden'
    }
}

export default COMPONENT_TEXTS
